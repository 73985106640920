.service-wrapper {
    background: white !important;
    padding: 40px 0;
    color: black;
}

.section-title {
    margin-bottom: 27px;
}

.section-title h1 {
    line-height: 1;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
}

.single-service-box {
    border: 1px solid #3672bc;
    border-radius: 10px;
    padding: 0px 47px 70px;
    margin-top: 30px;
    transition: .5s;
    background-image: linear-gradient(43deg,#11a69c,rgb(147,180,157));    
    text-align: center;
}

.single-service-box:hover {
    background: #f39425;
    background-image: linear-gradient(43deg,rgb(241, 177, 57),rgb(147,180,157));  
    cursor: cell;
    border-color: transparent;
}

.service-icon {
    margin-bottom: 24px;
    margin-top: 10px;
}

.single-service-box h3 {
    margin-bottom: 12px;
    font-size: 24px;
    font-family: "Josefin Sans", sans-serif;
    line-height: 36px;
}

.single-service-box p {
    color: #1d0f0f!important;
    font-family: 'Roboto Slab', serif;
}

.single-service-box a {
    color: #fff !important;
}

.single-service-box a:hover {
    color: #fc5185 !important;
}
