.carousel-container {
    width: 100%;
    height: 60vh;
  }
  
  /* Styles for mobile view */
  @media (max-width: 767px) {
    .carousel-container {
      padding: 20px; /* Example: Add padding for better spacing on mobile */
    }
  
    .slide-content {
      text-align: center; /* Example: Center-align the slide content on mobile */
    }
  
    .slide-title {
      font-size: 20px; /* Example: Decrease the font size of slide titles on mobile */
    }
  
    .slide-description {
      font-size: 14px; /* Example: Decrease the font size of slide descriptions on mobile */
    }
  
    .slide-button {
      margin-top: 10px; /* Example: Add margin to separate the button from the text on mobile */
    }
  }
  
  
  .slide {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .slide-content {
    padding-top: 8%;
    text-align: left;
    color: white;
    position: relative;
    z-index: 1;
    max-width: 600px; /* Adjust the maximum width as per your design */
    margin: 0 auto; /* Center align horizontally */
    align-items: left;
    margin-left: 260px;
  }
  
  .slide-title {
    font-size: 32px;
    font-weight: bold;
    animation: fade-in 1s ease-in-out;
  }
  
  .slide-description {
    font-size: 18px;
    animation: slide-up 1s ease-in-out;
    animation-delay: 0.5s;
  }
  
  .slide-button {
    padding: 10px 20px;
    background-color: #ff5c5c;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    animation: fade-in 1s ease-in-out;
    animation-delay: 1s;
  }
  
    
  .slide1 {
    background-image: url('../../../../../Images/slider/robotic-Knee-replacement-surgeon.jpg');
  }
  
  .slide2 {
    background-image: url('../../../../../Images/slider/surgeons-passing-scissors-each-other.jpg');
  }
  
  .slide3 {
    background-image: url('../../../../../Images/slider/pain-shoulder-joint.jpg');
  }
    
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slide-up {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  