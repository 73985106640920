.feature-wrapper {
    padding: 120px 0;
}
.single-feature-box {
    border-radius: 10px;
    background-image: linear-gradient(43deg,#11a69c,rgb(147,180,157));    background-color: #11a69c;
    box-shadow: 8.135px 18.271px 40px 0 rgb(0 0 0 / 8%);
    padding: 60px 50px 75px;
    transition: all .4s;
    text-align: center;
}

.single-feature-box:hover {
    transform: translateY(-10px);
    background: #f39425;
    background-image: linear-gradient(43deg,rgb(241, 177, 57),rgb(147,180,157));  
    cursor: cell;
    border-color: transparent;
}

.icon-box {
    display: flex;
    width: 103px;
    height: 68px;
    align-items: flex-end;
    text-align: center;
    margin: 0 auto 45px;
}

.icon-box img {
    display: inline-block;
    margin: 0 auto;
}

.single-feature-box h3 {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 36px;
    font-family: "Josefin Sans", sans-serif;
    color: white ;
    font-weight: bold;
}

.single-feature-box p {
    color: #080808;
    font-family: 'Roboto Slab', serif;
}


/* for mobile responsive */
@media only screen and (max-width: 426px){
    .feature-wrapper {
        padding: 50px 0;
    }
}