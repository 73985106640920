/* styles.css */

/* Bottom-to-top icons */
#bottomToTopIcons {
    position: fixed;
    bottom: 20px;
    right: 0.0000002%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Individual icon styles */
  #bottomToTopIcons .icon {
    margin-bottom: 10px;
    font-size: 30px;
    color: #444;
    cursor: pointer;
  }
  