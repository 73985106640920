.gallery-wrapper {
    background-color: #11a69c;
    background-image: linear-gradient(43deg,#11a69c,rgb(147,180,157));    

    padding: 120px 0;
}

.single-item-box img {
    border-radius: 12px;
    height: 332px;
    z-index: 1;
    margin-top: 30px;
    overflow: hidden;
    min-width: 100%;
}