.about-content-sec {
    padding: 20px 0;
    align-items:start;
    background-color: rgb(247, 187, 76);
    background-image: linear-gradient(43deg,rgb(241, 177, 57),rgb(147,180,157));  
}

.content-inner {
    color: #666;
    line-height: 1.5;
    font-size: 16px;
}


/* for mobile responsive */
@media only screen and (max-width: 426px){
    .content-inner {
        margin: 0 !important;
        width: 100% !important;
    }

    .about-content-sec {
        padding: 10px 0;
    }
}