.stars {
    display: inline-block;
  }
  
  .star {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  