.column {
    float: left;
    width: 50%;
    padding: 5px;
    height: auto;
  }
  
  /* Clearfix (clear floats) */
  .row::after {
    content: "";
    clear: both;
    display: table;
  }