.testimonial-wrapper {
    padding: 120px 0;
   
}

.client-box {
    border-radius: 10px;
    background-color: rgba(252,81,133,.05);
    box-shadow: 5.288px 11.876px 40px 0 rgb(0 0 0 / 5%);
    text-align: center;
    padding: 60px 58px 50px;
}

.client-img  {
    position: relative;
    width: 233px;
    height: 233px;
    margin-bottom: 24px;
    overflow: hidden;
    border-radius: 50%;
    z-index: 1;
    margin: auto;
}

.client-img::before {
    background-color: #fc5185;
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
}

.client-img img {
    position: absolute;
    text-align: center;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 7;
}

.client-box h3 {
    font-size: 24px;
    line-height: 36px;
    font-family: "Josefin Sans",sans-serif;
}

.client-box span, .review-item p {
    color: #666;
}

.review-item h5 {
    font-size: 18px;
    line-height: 26px;
    font-family: "Josefin Sans",sans-serif;
}


  